import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';

import { validateForm, onChange, getCookie } from '../../../utils';
import { getGoalReview, updateGoalMarks, approvedGoal } from '../../../action/employee/Goal';
import ModalWindow from '../../../component/common/ModalWindow';
import CONSTANT from '../../../constant';
import queryString from 'query-string';


class Review extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            showModal1: false,
            type: 'behaviour',
            user: undefined,
            designation: undefined,
            department: { value: [], name: 'department', error: '', isRequired: true },
            behaviour: { value: [], name: 'behaviour', error: '', isRequired: true },
            activity: { value: [], name: 'activity', error: '', isRequired: true },
            userCode: { value: [], name: 'userCode', error: '', isRequired: true },
            code: { value: [], name: 'code', error: '', isRequired: true },
            approved: { value: false, name: 'approved', error: '', isRequired: true },

            comments: { value: '', name: 'comments', error: '', isRequired: true },
            remarks: { value: '', name: 'remarks', error: '', isRequired: true },
            name: { value: [], name: 'name', error: '', boptions: [], doptions: [], aoptions: [], isRequired: true },
            weightage: { value: '', name: 'weightage', error: '', isRequired: true },

        }

    }

    componentDidMount() {
        this.loadReview()
    }

    public render() {

        const { user, designation, showModal, weightage, showModal1, remarks, type, approved, comments, behaviour, department, activity, name, } = this.state;
        console.log(user, designation)
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Start Appraisal / Review</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>  Appraisal / Review
                                            </b>
                                        </div>

                                        <div className="card-body">
                                            <p>  <span style={{ fontWeight: 'bold' }}>Employee Code :</span>&nbsp;&nbsp;<span>{user && user.empCode}</span></p>
                                            <p>  <span style={{ fontWeight: 'bold' }}>Employee Name :</span>&nbsp;&nbsp;<span>{user && user.name}</span></p>
                                            <p><span style={{ fontWeight: 'bold' }}>Employee Designation :</span>&nbsp;&nbsp;<span>{designation && designation}</span></p>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-width3"> Behaviour</th>
                                                            <th scope="col" className="md-width3">Weightage</th>
                                                            <th scope="col" className="md-width3">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-width3">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {behaviour.error.length > 0 && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum 2 behaviour</span> </td>
                                                        </tr>}
                                                        {
                                                            behaviour.value.length > 0 && behaviour.value.map((item: any, ind: any) => {

                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}
                                                                        </td>
                                                                        <th>
                                                                            <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, behaviour.name)}></i> &nbsp;&nbsp;&nbsp;
                                                                            {/* <i className="fa fa-close  add-plus" onClick={() => this.onDelete(ind, behaviour.name)}></i> */}
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-width3"> Skills</th>
                                                            <th scope="col" className="md-width3">Weightage</th>
                                                            <th scope="col" className="md-width3">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-width3">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {department.error.length > 0 && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum 2 department</span> </td>
                                                        </tr>}
                                                        {
                                                            department.value.length > 0 && department.value.map((item: any, ind: any) => {

                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}
                                                                        </td>

                                                                        <th>
                                                                            <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, department.name)}></i>&nbsp;&nbsp;&nbsp;
                                                                            {/* <i className="fa fa-close  add-plus" onClick={() => this.onDelete(ind, department.name)}></i> */}
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-width3">Activities</th>
                                                            <th scope="col" className="md-width3">Weightage</th>
                                                            <th scope="col" className="md-width3">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-width3">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            activity.value.length > 0 && activity.value.map((item: any, ind: any) => {

                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}
                                                                        </td>
                                                                        <th>
                                                                            <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, activity.name)}></i>&nbsp;&nbsp;&nbsp;
                                                                            {/* <i className="fa fa-close  add-plus" onClick={() => this.onDelete(ind, activity.name)}></i> */}
                                                                        </th>
                                                                    </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12 mt-3 mb-3">
                                    {approved.value === 'pending' && < div className="col-lg-12 mt-3 mb-3">
                                        <button onClick={this.onSubmit} className="col-lg-2 btn button-full pull-right">Approve </button>
                                        <button onClick={() => { this.toggleWindow1() }} className="col-lg-2 btn  btn-danger mr-3 pull-right"> Reject </button>
                                    </div>}
                                    {approved.value === 'approved' && < div className="col-lg-12 mt-3 mb-3">
                                        <Link to={CONSTANT.url.empAppraiseEmployee} className="col-lg-2 btn btn-secondary mr-3 pull-right"> Go Back </Link>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showModal && (
                        <ModalWindow
                            title={"Comments"}
                            backdrop="static"
                            toggleModal={() => { this.toggleWindow() }}
                            className="modal-lg" >
                            <div>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div>
                                            <form>
                                                <div className="mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 form-group">
                                                            <label> Name  </label>
                                                            <Typeahead
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                placeholder="Select name"
                                                                name={name.name}
                                                                minLength={0}
                                                                options={type == behaviour.name ? name.boptions : type == department.name ? name.doptions : name.aoptions}
                                                                selected={name.value}
                                                                isInvalid={name.error.length > 0}
                                                                disabled
                                                                labelKey="name"
                                                            />

                                                        </div>

                                                        <div className="col-lg-6 form-group">
                                                            <label>Weightage </label>
                                                            <input
                                                                name={weightage.name}
                                                                onChange={this._onChange}
                                                                value={weightage.value}
                                                                type="text"
                                                                className={weightage.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter weightage"
                                                            />

                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Remarks </label>
                                                            <textarea
                                                                name={remarks.name}
                                                                onChange={this._onChange}
                                                                value={remarks.value}
                                                                className={remarks.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter remarks"
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <button onClick={this.onUpdate} className="col-lg-2 btn button-full pull-right">Submit</button>
                                                            <button onClick={() => { this.toggleWindow() }} className="col-lg-2 btn btn-secondary mr-3 pull-right">Cancel</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalWindow>
                    )}

                {
                    showModal1 && (
                        <ModalWindow
                            title={"Comments"}
                            backdrop="static"
                            toggleModal={() => { this.toggleWindow() }}
                            className="modal-lg" >
                            <div>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div>
                                            <form>
                                                <div className="mb-2">
                                                    <div className="row">

                                                        <div className="col-lg-12 form-group">
                                                            <label>Comments </label>
                                                            <textarea
                                                                name={comments.name}
                                                                onChange={this._onChange}
                                                                value={comments.value}
                                                                className={comments.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter Comments"
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <button onClick={this.onSubmit1} className="col-lg-2 btn button-full pull-right">Submit</button>
                                                            <button onClick={() => { this.toggleWindow1() }} className="col-lg-2 btn btn-secondary mr-3 pull-right">Cancel</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalWindow>
                    )}
            </React.Fragment>

        )
    }

    private toggleWindow = (e?: any,) => {
        const { showModal, } = this.state
        e && this.setState({ type: e })
        this.setState({ showModal: !showModal });
        if (showModal)
            this.setState({
                weightage: { ...this.state.weightage, value: '' },
                remarks: { ...this.state.remarks, value: '' },
                name: { ...this.state.name, value: '' },

            })
    }

    private toggleWindow1 = (e?: any,) => {
        debugger
        const { showModal1, } = this.state
        e && this.setState({ type: e })
        this.setState({ showModal1: !showModal1 });
        if (showModal1)
            this.setState({
                comments: { ...this.state.comments, value: '' },
            })
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }
    private onEdit = (i: Number, item: any, type: String,) => {
        this.setState({
            name: { ...this.state.name, value: [{ code: item.code, name: item.name }] },
            weightage: { ...this.state.weightage, value: item.weightage },
            remarks: { ...this.state.remarks, value: item.remarks },
            updateByName: item.name,
        });
        this.toggleWindow(type)
    }



    private onUpdate = (e: any) => {
        e.preventDefault()
        const { type, activity, behaviour, weightage, updateByName, remarks, department }: any = this.state
        if (validateForm(this, { remarks, weightage })) {
            if (type == activity.name) {

                const index = activity.value.findIndex((item: any) => item.name === updateByName);
                activity.value[index] = { ...activity.value[index], weightage: weightage.value, remarks: remarks.value }
                onChange(this, activity.name, activity.value);
            }
            else if (type == behaviour.name) {

                const index = behaviour.value.findIndex((item: any) => item.name === updateByName);
                behaviour.value[index] = { ...behaviour.value[index], weightage: weightage.value, remarks: remarks.value }
                onChange(this, behaviour.name, behaviour.value);
            }
            else if (type == department.name) {

                const index = department.value.findIndex((item: any) => item.name === updateByName);
                department.value[index] = { ...department.value[index], weightage: weightage.value, remarks: remarks.value }
                onChange(this, department.name, department.value);
            }
            this.toggleWindow()
        }
    }


    loadReview = () => {

        const values = queryString.parse(this.props.location.search);
        getGoalReview(values).then((res: any) => {

            if (res.result) {
                this.setState({
                    user: res.result.user,
                    designation: res.result.designation,
                    department: { ...this.state.department, value: res.result ? res.result.department : [] },
                    behaviour: { ...this.state.behaviour, value: res.result ? res.result.behaviour : '' },
                    activity: { ...this.state.activity, value: res.result ? res.result.activity : [] },
                    comments: { ...this.state.comments, value: res.result ? res.result.comments : [] },
                    userCode: { ...this.state.userCode, value: res.result ? res.result.userCode : [] },
                    code: { ...this.state.code, value: res.result ? res.result.code : [] },
                    approved: { ...this.state.approved, value: res.result.approved },
                })
            }
        });
    }

    private onSubmit1 = (e: any) => {
        const { yearCycle, monthCycle } = queryString.parse(this.props.location.search);
        e.preventDefault();
        const model = {
            reject: true,
            code: this.state.code.value,
            comments: this.state.comments.value,
            userCode: this.state.userCode.value,
            empCode: JSON.parse(window.atob(getCookie('token').split('.')[1])).empCode,
            yearCycle, monthCycle


        };
        this.setState({ showLoader: true })
        approvedGoal(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response) {
                window.alert("Goal Rejected")
                this.props.history.push(CONSTANT.url.empAppraiseEmployee);
            }
        });
    }
    private onSubmit = (e: any) => {
        debugger
        e.preventDefault();
        const { yearCycle, monthCycle } = queryString.parse(this.props.location.search);
        const model = {
            approved: true,
            code: this.state.code.value,
            userCode: this.state.userCode.value,
            empCode: JSON.parse(window.atob(getCookie('token').split('.')[1])).empCode,
            yearCycle, monthCycle

        };
        this.setState({ showLoader: true })
        approvedGoal(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response) {
                window.alert("Goal Approved")
                this.props.history.push(CONSTANT.url.empAppraiseEmployee);
            }
        });
    }
}

export default Review;
