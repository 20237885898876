import * as React from 'react';
import { Link } from 'react-router-dom';

import complete from '../../assets/images/menu-icon2.png';
import total from '../../assets/images/home-icon1.png';
import app from '../../assets/images/home-icon2.png';
import add from '../../assets/images/home-icon4.png';
import Loader from '../../component/common/Loader';
import { getEmpDashboard, getAppraisalCycle, userDetailSuperReports, getEmpReportByAppraisalCycle } from '../../action/Master'
import CONSTANT from '../../constant';
import { onChange } from '../../utils';

class Dashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            prevYearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            prevMonthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            /****Add By Jitender** */
            lastYearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            lastMonthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            NextYearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            NextMonthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            /***End** */
            list: [],
            showLoader: false,
            goalList: undefined,
            acList: [],
            prevYearList: [],
            /**Add By Jitender** */
            lastYearList: [],
            NextYearList: []
            /***End */

        }
    }
    componentWillMount() {
        this.loadList()
    }
    public render() {
        const { list, yearCycle, monthCycle, showLoader, acList, prevYearList, lastYearList, NextYearList } = this.state;
        return (
            <React.Fragment>

                <div className="col-lg-12 main-container">
                    <div className="fluid-container">

                        <div className="card mt-2">
                            <div className="card-header">
                                <b>Filter</b>
                                <div className="col-lg-6 pull-right">
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    <form>
                                        {
                                            list !== undefined && list.map((item: any, index: number) => (
                                                (
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr><label style={{ padding: "10px" }}> Employee Name </label><th>{item.name}</th> </tr>
                                                                        <tr><label style={{ padding: "10px" }}> Designation </label><th>{item.designation}</th> </tr>
                                                                        <tr><label style={{ padding: "10px" }}> Department </label><th>{item.department}</th> </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <table className="table table-bordered table-hover">
                                                                    <tbody>
                                                                        <tr><label style={{ padding: "10px" }}> Employee Code </label><td>{item.empCode}</td> </tr>
                                                                        <tr><label style={{ padding: "10px" }}> Location </label><td>{item.location}</td> </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>)))}
                                    </form>

                                }
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-header"><b>2020-2021 Reports Status</b></div>
                            <div className="card-body">
                                <div className="list-container">

                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="col">Quarter</th>
                                                <th scope="col">Goal Setting</th>
                                                <th scope="col">Goal Approval</th>
                                                <th scope="col">Self Appraisal</th>
                                                <th scope="col">Appraisal by Reporting Officer</th>
                                                <th scope="col">Appraisal by Reviewing Officer</th>
                                                <th scope="col">Second Reporting Officer </th>
                                                <th scope="col">Second Reviewing Officer </th>
                                                <th scope="col">Status </th>
                                            </tr>
                                            {
                                                prevYearList === undefined || prevYearList.length === undefined && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {console.log("20-21", prevYearList)}
                                            {
                                                prevYearList !== undefined && prevYearList.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {
                                                prevYearList !== undefined && prevYearList.map((item: any, index: number) => {
                                                    const finalApproved = item.appraisalByReportingOfficer && item.appraisalByReviewingOfficer ? 'Approved' : 'Pending'
                                                    return (
                                                        <tr>
                                                            <th>{item.quarter[0]}</th>
                                                            <th>{item.noOfGoals == "0" ? 0 : item.noOfGoals}</th>
                                                            <th>{item.approved != "approved" ? item.approved : "Completed"}</th>
                                                            <th>{item.selfAppraisal == "0" ? 'Pending' : 'Completed'}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReportingOfficer == "0" && `Pending : ${item.reportingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.appraisalByReportingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            <th>{item.appraisalByReviewingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReviewingOfficer == "0" && `Pending : ${item.reviewingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th>
                                                            {/* <th>{item.approved}</th> */}
                                                            <th>{finalApproved}</th>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-header"><b>2021-2022 Reports Status</b></div>
                            <div className="card-body">
                                <div className="list-container">

                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="col">Quarter</th>
                                                <th scope="col">Goal Setting</th>
                                                <th scope="col">Goal Approval</th>
                                                <th scope="col">Self Appraisal</th>
                                                <th scope="col">Appraisal by Reporting Officer</th>
                                                <th scope="col">Appraisal by ReViewing Officer</th>
                                                <th scope="col">Second Reporting Officer </th>
                                                <th scope="col">Second Reviewing Officer </th>
                                                <th scope="col">Status </th>
                                            </tr>
                                            {
                                                lastYearList === undefined || lastYearList.length === undefined && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {console.log("21-22", lastYearList)}
                                            {
                                                lastYearList !== undefined && lastYearList.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {
                                                lastYearList !== undefined && lastYearList.map((item: any, index: number) => {
                                                    const finalApproved = item.appraisalByReportingOfficer && item.appraisalByReviewingOfficer ? 'Approved' : 'Pending'
                                                    return (
                                                        <tr>
                                                            <th>{item.quarter[0]}</th>
                                                            <th>{item.noOfGoals == "0" ? 0 : item.noOfGoals}</th>
                                                            <th>{item.approved != "approved" ? item.approved : "Completed"}</th>
                                                            <th>{item.selfAppraisal == "0" ? 'Pending' : 'Completed'}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReportingOfficer == "0" && `Pending : ${item.reportingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.appraisalByReportingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            <th>{item.appraisalByReviewingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReviewingOfficer == "0" && `Pending : ${item.reviewingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th>
                                                            {/* <th>{item.approved}</th> */}
                                                            <th>{finalApproved}</th>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* /***Add By Jitender** */}
                        <div className="card mt-2">
                            <div className="card-header"><b>2022-2023 Reports Status</b></div>
                            <div className="card-body">
                                <div className="list-container">

                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="col">Quarter</th>
                                                <th scope="col">Goal Setting</th>
                                                <th scope="col">Goal Approval</th>
                                                <th scope="col">Self Appraisal</th>
                                                <th scope="col">Appraisal by Reporting Officer</th>
                                                <th scope="col">Appraisal by ReViewing Officer</th>
                                                <th scope="col">Second Reporting Officer </th>
                                                <th scope="col">Second Reviewing Officer </th>
                                                <th scope="col">Status </th>
                                            </tr>
                                            {
                                                acList === undefined || acList.length === undefined && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {console.log("22-23",NextYearList)
                                            }
                                            {
                                                NextYearList !== undefined && NextYearList.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {
                                                NextYearList !== undefined && NextYearList.map((item: any, index: number) => {
                                                    /***Add By Jitender**** */
                                                    const finalApproved = item.appraisalByReportingOfficer && item.appraisalByReviewingOfficer ? 'Approved' : 'Pending'
                                                    /****End**** */

                                                    return (
                                                        <tr>
                                                            <th>{item.quarter[0]}</th>
                                                            <th>{item.noOfGoals == "0" ? 0 : item.noOfGoals}</th>
                                                            <th>{item.approved != "approved" ? item.approved : "Completed"}</th>
                                                            <th>{item.selfAppraisal == "0" ? 'Pending' : 'Completed'}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReportingOfficer == "0" && `Pending : ${item.reportingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.appraisalByReportingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            <th>{item.appraisalByReviewingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReviewingOfficer == "0" && `Pending : ${item.reviewingManager[0].name}`) : item.approved}</th> */}
                                                            {/***Add By jitender */}
                                                            <th>{item._id == "6255451db310641d5d048ebd" ? "No Data" : item._id == "625d4679b310641d5d04903e" ? "No Data" : item._id == "62c6a66e0e91f635ac48b458" ? "No Data" : item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item._id == "6255451db310641d5d048ebd" ? "No Data" : item._id == "625d4679b310641d5d04903e" ? "No Data" : item._id == "62c6a66e0e91f635ac48b458" ? "No Data" : item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th>
                                                            {/******End** */}
                                                            {/* <th>{item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th> */}
                                                            {/* <th>{item.approved}</th> */}
                                                            <th>{finalApproved}</th>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-2">
                            <div className="card-header"><b>2023-2024 Reports Status</b></div>
                            <div className="card-body">
                                <div className="list-container">

                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="col">Quarter</th>
                                                <th scope="col">Goal Setting</th>
                                                <th scope="col">Goal Approval</th>
                                                <th scope="col">Self Appraisal</th>
                                                <th scope="col">Appraisal by Reporting Officer</th>
                                                <th scope="col">Appraisal by ReViewing Officer</th>
                                                <th scope="col">Second Reporting Officer </th>
                                                <th scope="col">Second Reviewing Officer </th>
                                                <th scope="col">Status </th>
                                            </tr>
                                            {
                                                acList === undefined || acList.length === undefined && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {console.log("test",acList)
                                            }
                                            {
                                                acList !== undefined && acList.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No records found</td>
                                                </tr>
                                            }
                                            {
                                                acList !== undefined && acList.map((item: any, index: number) => {
                                                    /***Add By Jitender**** */
                                                    const finalApproved = item.appraisalByReportingOfficer && item.appraisalByReviewingOfficer ? 'Approved' : 'Pending'
                                                    /****End**** */

                                                    return (
                                                        <tr>
                                                            <th>{item.quarter[0]}</th>
                                                            <th>{item.noOfGoals == "0" ? 0 : item.noOfGoals}</th>
                                                            <th>{item.approved != "approved" ? item.approved : "Completed"}</th>
                                                            <th>{item.selfAppraisal == "0" ? 'Pending' : 'Completed'}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReportingOfficer == "0" && `Pending : ${item.reportingManager[0].name}`) : item.approved}</th> */}
                                                            <th>{item.appraisalByReportingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            <th>{item.appraisalByReviewingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                            {/* <th>{item.approved == "pending" ? (item.appraisalByReviewingOfficer == "0" && `Pending : ${item.reviewingManager[0].name}`) : item.approved}</th> */}
                                                            {/***Add By jitender */}
                                                            <th>{item._id == "6255451db310641d5d048ebd" ? "No Data" : item._id == "625d4679b310641d5d04903e" ? "No Data" : item._id == "62c6a66e0e91f635ac48b458" ? "No Data" : item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item._id == "6255451db310641d5d048ebd" ? "No Data" : item._id == "625d4679b310641d5d04903e" ? "No Data" : item._id == "62c6a66e0e91f635ac48b458" ? "No Data" : item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th>
                                                            {/******End** */}
                                                            {/* <th>{item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                            <th>{item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th> */}
                                                            {/* <th>{item.approved}</th> */}
                                                            <th>{finalApproved}</th>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* /****END*** */}


                    </div>
                </div>
                {
                    showLoader && <Loader />
                }

            </React.Fragment>

        )
    }

    private _setError(name: string, error: string) {
        this.setState({ [name]: { ...this.state[name], error } });
    }


    renderSelect = (select: any, f: boolean) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                {/* <option>--Select--</option> */}
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                mnth = mnth.length && mnth[0].sort((a: any, b: any,) => b.createdOn - a.createdOn);
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: mnth },
                    // monthCycle: { ...this.state.monthCycle,  mnth },

                }, () => {
                    this.loadEmployee();

                })
            }
            if (name == this.state.monthCycle.name) {
                this.loadEmployee()
            }
        })

    }

    private loadList = () => {

        getAppraisalCycle()
            .then((res: any) => {
                if (res && res.result && res.result.length)

                    this.setState({
                        yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                        monthCycle: { ...this.state.monthCycle, options: res.result[0].performance.length > 0 ? res.result[0].performance : [], value: res.result[0].performance.length > 0 ? res.result[0].performance[0].code : '' },
                        prevYearCycle: { ...this.state.yearCycle, options: res.result, value: "dnrqdvedzfk87asxw7" },
                        prevMonthCycle: { ...this.state.monthCycle, options: res.result[1].performance.length > 0 ? res.result[1].performance : [], value: res.result[1].performance.length > 0 ? res.result[1].performance[1].code : '' },
                        /**Add By Jitender*** */
                        lastYearCycle: { ...this.state.yearCycle, options: res.result, value: "376sd3o9ifkqruswz9" },
                        lastMonthCycle: { ...this.state.monthCycle, options: res.result[1].performance.length > 0 ? res.result[1].performance : [], value: res.result[1].performance.length > 0 ? res.result[1].performance[1].code : '' },
                        NextYearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[1].code },
                        NextMonthCycle: { ...this.state.monthCycle, options: res.result[1].performance.length > 0 ? res.result[1].performance : [], value: res.result[1].performance.length > 0 ? res.result[1].performance[1].code : '' },
                        /***End** */
                    }, () => this.loadEmployee());
                this.loadReportByAppraisalCycle();
                this.loadPreviousReportByAppraisalCycle();
                this.loadlastReportByAppraisalCycle();
                this.loadNextReportByAppraisalCycle();

            });

    }


    private loadReportByAppraisalCycle = () => {
        debugger
        const { yearCycle, monthCycle } = this.state;
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getEmpReportByAppraisalCycle(model).then((res: any) => {
            this.setState({ acList: res.result });
        })
    }

    private loadPreviousReportByAppraisalCycle = () => {


        const { prevYearCycle, prevMonthCycle } = this.state;
        const model = {
            yearCycle: prevYearCycle.value,
            monthCycle: prevMonthCycle.value,
        }
        getEmpReportByAppraisalCycle(model).then((res: any) => {
            console.log("prevYearList>>>>", res.result);

            this.setState({ prevYearList: res.result });
        })
    }

    /***Add By Jitender*** */
    private loadlastReportByAppraisalCycle = () => {

        const { lastYearCycle, lastMonthCycle } = this.state;
        const model = {
            yearCycle: lastYearCycle.value,
            monthCycle: lastMonthCycle.value,
        }
        getEmpReportByAppraisalCycle(model).then((res: any) => {
            // debugger
            this.setState({ lastYearList: res.result });
        })
    }

    /****End**** */

     /***Add By Jitender*** */
     private loadNextReportByAppraisalCycle = () => {

        const { NextYearCycle, NextMonthCycle } = this.state;
        const model = {
            yearCycle: NextYearCycle.value,
            monthCycle: NextMonthCycle.value,
        }
        getEmpReportByAppraisalCycle(model).then((res: any) => {
            // debugger
            this.setState({ NextYearList: res.result });
        })
    }

    /****End**** */

    loadEmployee = () => {

        this.setState({ showLoader: true })
        const { yearCycle, monthCycle } = this.state;
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getEmpDashboard(model)
            .then((res: any) => {
                this.setState({ showLoader: false })
                if (res)
                    this.setState({ list: [res.result] })

            })

    }


}

export default Dashboard;