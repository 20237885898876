import React from 'react';
import ReactDOM from 'react-dom';
import { savePDF } from '@progress/kendo-react-pdf';
import pdfLogo from '../../assets/images/logo_pdf.png';
// import GoalHistory from '../../container/employee/setGoal/GoalHistory';
import { onChange } from '../../utils';
import moment from 'moment';


export default class App extends React.Component<any, any> {
    table: HTMLElement | any;

    constructor(props: any) {
        super(props);
        this.state = {
            data: props.data, //gradeValue
            OverAllPer: props.OverAllPer,
            gradeValue: props.gradeValue,
            yearCycleData: props.yearCycleData,
            updateData: props.updateData,
            filter: { name: 'filter', value: 'all', error: '', isRequired: true, },
            finalRating: '',
            finalGrade: [],
            Grade: props.Grade,
            remarks: false,
        }
    }
    render() {
        const { gradeValue, data, OverAllPer, updateData, yearCycleData, remarks } = this.state
        const { yearCycle } = data[0]
        console.log(data, 'data2');

        return (
            <section>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-2">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="col-12 col-lg-12 form-group" >
                                        <button className="col-lg-3 btn button-full mt-4 pull-right" onClick={this.printPDF}>Download Report</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body" id="printArea" style={{ marginTop: 60 }} ref={(table) => { this.table = table; }}>
                                <h3 className="col-12 col-lg-12 form-group" style={{ textAlign: "center" }}> <b> {`PMS APPRAISAL RATING REPORT OF  ${this.props.yearCycleData[0].yearCycle.replace(/\s+/g, ' ').toLowerCase()} `}</b></h3>

                                <div className="col-12 col-lg-12 form-group">
                                    < table className="table table-bordered tab table-responsive" >
                                        < thead >
                                            <tr>
                                                <th>SI</th>
                                                <th>Name of the Employee</th>
                                                <th>Employee Code</th>
                                                <th>Department </th>
                                                <th>Designation </th>
                                                <th>April - June</th>
                                                <th>July - September</th>
                                                <th>October - December</th>
                                                <th>January - March</th>
                                                <th>Over All Percentage</th>
                                                <th>Grade</th>
                                                {/* <th style={{
                                                    width: "150px", display: "flow-root",
                                                    height: "68px"
                                                }}><div style={{ cursor: "pointer" }} onClick={() => this.Edit()}><i className="fa fa-pencil  add-plus" ></i> Add Remarks</div></th> */}

                                            </tr>
                                        </thead>

                                        {updateData.length > 0 && updateData.map((l: any, i: number) => {
                                            {
                                                console.log("updata", l);
                                            }
                                            return (
                                                <>
                                                    <tbody>

                                                        <tr key={i + "pdt"}>
                                                            <td>{i + 1}</td>
                                                            <td>{l.uniqueNames}</td>
                                                            <td>{l.uniqueEmpCode}</td>
                                                            <td> {l.uniqueDepartment}</td>
                                                            <td> {l.uniqueDesignation}</td>
                                                            {
                                                                yearCycle === '2020 - 2021'
                                                                &&
                                                                <><td>{l.marksGrade.map((i: any) => i.monthCycle == 'dnrqdvedzfk87awmm2' ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == 'dnrqdvef6bkc04ejnq' ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == 'd9oil48kfs14d6j' ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == 'd9oie73kji5v9h5' ? i.status : null)}</td></>

                                                            }
                                                            {
                                                                yearCycle === '2021-2022'
                                                                &&
                                                                <><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3o9ifkqruw5zk" ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3ob0okrq10m05" ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3oavbkufsnirs" ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == '376sd3ohhkkxy745tn' ? i.status : null)}</td></>
                                                            }

                                                            {
                                                                yearCycle === '2022-2023'
                                                                &&
                                                                <><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3o24xl1n60uck" ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3ooodl4y5k9hb" ? i.status : null)}</td><td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3o25pl8jumow4" ? i.status : null)}</td>
                                                                    {/* <td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3o5fnlcefltdc" ? i.status : null)}</td> */}
                                                                    {l.uniqueNames == "MAHESH KUMAR SWARNKAR" ?
                                                                        <td>
                                                                            {l.marksGrade.map((i: any) => i.status == "Out Standing" ? "Un Satisfactorys" : "")}
                                                                        </td> : <td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3o5fnlcefltdc" ? i.status : null)}</td>}
                                                                </>
                                                            }

                                                            {
                                                                yearCycle === '2023-2024'
                                                                &&
                                                                <>
                                                                <td>{l.marksGrade.map((i: any) => i.monthCycle == "376sd3olmllf25fg7a" ? i.status : null)}
                                                                </td>
                                                                <td>
                                                                 {l.marksGrade.map((i: any) => i.monthCycle == "376sd3ob33ljsay67s" ? i.status : null)}
                                                                 </td>

                                                                    <td>
                                                                        {l.marksGrade.map((i: any) => i.monthCycle == "376sd3o28almkduvm4" ? i.status : null)}
                                                                    </td>
                                                                    <td>
                                                                        {l.marksGrade.map((i: any) => i.monthCycle == "376sd3o1yllquhc0ud" ? i.status : null)}
                                                                    </td>
                                                                    </>


                                                            }

                                                           {/* {
                                                                yearCycle === '2024-2025'
                                                                &&
                                                                <>
                                                                    <td>
                                                                        {l.marksGrade.map((i: any) => i.monthCycle == "376sd3oi6alu0qowxs" ? i.status : null)}
                                                                    </td>
                                                                </>
                                                          } */}

                                                            





                                                            {/* <td>{parseInt(l.totalMarks[0] == 'null' || l.totalMarks[0] == undefined ? 0 : l.totalMarks[0].toFixed(3))}</td>
                                                            <td>{parseInt(l.totalMarks[2] == 'null' || l.totalMarks[2] == undefined ? 0 : l.totalMarks[2].toFixed(3))}</td>
                                                            <td>{parseInt(l.totalMarks[3] == 'null' || l.totalMarks[3] == undefined ? 0 : l.totalMarks[3].toFixed(3))}</td> */}
                                                            <td>{parseInt(OverAllPer[i].average)}</td>
                                                            <td>{OverAllPer[i].Grade}</td>
                                                            {/* {remarks &&
                                                                <td id='Remarks' style={{
                                                                    width: "150px", display: "flow-root",
                                                                    height: "68px"
                                                                }}><div style={{ display: "flex", alignItems: "center" }}><input type="text" style={{ marginRight: "5px", width: "100px" }} /> <i className="fa fa-check add-plus" onClick={(event) => this.Remarks(event)}></i> &nbsp; <i className="fa fa-close add-plus" onClick={() => this.close()}></i></div> </td>} */}
                                                        </tr>
                                                    </tbody></>
                                            )
                                        })
                                        }

                                    </table>
                                    <br className='page-break' />

                                </div></div>
                        </div>

                    </div>
                </div >
            </section >
        );

    }

    private _onChange = (e: any) => {

        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
        this.filter(value)
    }

    private filter = (f: any) => {
        const res = this.props.UserListData.length > 0 && this.props.UserListData.filter((item: any) => {
            if (f === 'completed')
                return item.goal.selfAppraisal === true;
            else if (f === 'pending')
                return item.goal.approved === 'pending';
            else if (f === 'reject')
                return item.goal.approved === 'reject';
            else if (f === 'approved') {
                return item.goal.approved === 'approved';
            }
            else if (f === 'dualReporting') {
                return item.secondReportingOfficer.length > 0 && item.secondReviewingOfficer.length > 0
            }
            else return true

        })
        this.setState({ data: res });
        this.refreshPage()
    }

    refreshPage = () => {
        window.location.reload();

    }

    Edit = () => {
        this.setState({ remarks: true })
        //window.location.reload();
    }

    close = () => {
        this.setState({ remarks: false })
    }

    Remarks = (event: any) => {
        const { value } = event.target
        // postRemarks(value).then((res: any) => {
        //     console.log("res", res)
        // })

    }

    // }
    //     private printPDF = ()=>{
    //         //  @ts-ignore 
    //     var style = "<style>";
    //     style = style + "table {width: 100%;height: 100%;font: 17px Calibri;}";
    //     style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    //     style = style + "padding: 2px 3px;text-align: center;}";
    //     style = style + "</style>";
    //     //
    //     var tab = document.getElementById("printArea");
    //             //  @ts-ignore 
    //     var win = window.open('', '', 'width=1200,height=700');
    //                 //  @ts-ignore 
    //     win.document.write(style);
    //                     //  @ts-ignore 
    //     win.document.write(tab.outerHTML);
    //                     //  @ts-ignore 
    //     win.document.close();
    //                     //  @ts-ignore 
    //     win.print();
    //    }

    exportPDF = () => {
        const elelmetn: HTMLElement | any = ReactDOM.findDOMNode(this.table)
        savePDF(elelmetn, {
            repeatHeaders: false,
            paperSize: "A4",
            margin: '0cm',
            forcePageBreak: ".page-break",
            date: new Date(),
            fileName: this.props.data.length > 0 ? `Annual Appriasial Rating Report - ${this.props.yearCycleData[0].yearCycle.replace(/\s+/g, '-').toLowerCase()}` : `${moment().format('YYYY-MM-DD-hh:mm')}`,
            scale: 0.6
        });
    }


    printPDF = () => {
        const elelmetn: HTMLElement | any = ReactDOM.findDOMNode(this.table)
        savePDF(elelmetn, {
            repeatHeaders: true,
            paperSize: "A4",
            margin: '0.5cm',
            landscape: true,
            date: new Date(),                                                             //props.yearCycleData
            fileName: this.props.data.length > 0 ? `Annual Appriasial Rating Report - ${this.props.yearCycleData[0].yearCycle.replace(/\s+/g, '-').toLowerCase()}` : `${moment().format('YYYY-MM-DD-hh:mm')}`,
            scale: 0.420,

        });
    }

}
